import styles from "./Card.module.scss";

function Card(props) {
  const redirectHandler = () => {
    if (props.link) {
      window.location.href = props.link;
    }
  };

  return (
    <div
      className={`${styles.cardContainer} ${props.link && styles.isLink}`}
      onClick={redirectHandler}
    >
      <div className={styles.card}>{props.children}</div>
    </div>
  );
}

export default Card;
