import { RequestInstructions } from "components/Request/RequestInstructions";
import { RequestForm } from "components/Request/RequestForm";
import { NoFormRequest } from "components/Request/NoFormRequest";

import { ScrollDownHint } from "components/Buttons/ScrollDownHint";
import { PageWidth } from "components/PageWidth";
import { Card } from "components/Card/Card";
import { CardLayout } from "components/Card/CardLayout";
import { Separator } from "components/Separator";

import MailIcon from "images/requestSvg/mail.svg";
import DocumentIcon from "images/requestSvg/document.svg";
import LatheIcon from "images/requestSvg/lathe.svg";
import ShippingIcon from "images/requestSvg/shipping.svg";

export function RequestPage() {
  return (
    <>
      <PageWidth>
        <CardLayout>
          <Card>
            <RequestInstructions image={MailIcon} n={"1"}>
              Zašlete nám{" "}
              <span className="strong-text">výkresovou dokumentaci</span>,
              <br /> a požadované <span className="strong-text">množství</span>
            </RequestInstructions>
          </Card>

          <Card>
            <RequestInstructions image={DocumentIcon} n={"2"}>
              Vytvoříme Vám <span className="strong-text">cenovou nabídku</span>
              <br /> a budeme čekat na{" "}
              <span className="strong-text">vaše potvrzení</span>
            </RequestInstructions>
          </Card>

          <Card>
            <RequestInstructions image={LatheIcon} n={"3"}>
              <span className="strong-text">Vyhotovíme</span> Vaši zakázku,
              <br /> <span className="strong-text">materiál zajistíme</span>
            </RequestInstructions>
          </Card>

          <Card>
            <RequestInstructions image={ShippingIcon} n={"4"}>
              <span className="strong-text">Doručíme</span> hotové zboží na Vámi
              zvolenou adresu,
              <br /> nebo si ho u nás můžete sami{" "}
              <span className="strong-text">vyzvednout</span>
            </RequestInstructions>
          </Card>
        </CardLayout>
        {/* <ScrollDownHint /> */}
      </PageWidth>

      <Separator />

      <RequestForm />
      <NoFormRequest />
    </>
  );
}
