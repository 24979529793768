import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { Routes } from "./Routes";
import { AppLayout } from "./pages/AppLayout";
import "./App.scss";

function App() {
  return (
    <Router>
      <AppLayout>
        <Routes />
      </AppLayout>
    </Router>
  );
}

export default App;
