import React, { useState } from "react";
import ReactDOM from "react-dom";
import styles from "./NavigationMenu.module.scss";
import BurgerMenuIcon from "images/burgerMenuIcon.svg";
import CancelButtonIcon from "images/cancelButtonIcon.svg";

function Backdrop(props) {
  if (props.show) {
    return ReactDOM.createPortal(
      <div
        className={`${styles.backdrop} ${props.show && styles.show}`}
        onClick={props.handleClose}
      />,
      document.getElementById("backdrop-root")
    );
  } else {
    return null;
  }
}

function NavMenu(props) {
  if (props.show) {
    return ReactDOM.createPortal(
      <div className={`${styles.navMenu} ${props.show && styles.show}`}>
        <button
          className={`${styles.closeButton} ${props.show && styles.show}`}
          onClick={props.handleClose}
        >
          Zavřít <img src={CancelButtonIcon} alt='cancelButtonIcon' />
        </button>
        {props.children}
      </div>,
      document.getElementById("navigation-root")
    );
  } else {
    return <div className={styles.navMenu}>{props.children}</div>;
  }
}

function NavigationMenu(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className={styles.burgerIconButton} onClick={handleShow}>
        <img src={BurgerMenuIcon} alt='burgerMenuIcon' />
      </button>
      <Backdrop show={show} handleClose={handleClose} />
      <NavMenu
        show={show}
        handleClose={handleClose}
        children={props.children}
      />
    </>
  );
}

export default NavigationMenu;
