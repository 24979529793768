import React from "react";
import { Route, Switch } from "react-router-dom";
import { ContactPage } from "./pages/ContactPage";
import { GalleryNavigationPage } from "./pages/GalleryNavigationPage";
import { GalleryPage } from "./pages/GalleryPage";
import { IndexPage } from "./pages/IndexPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { RequestPage } from "./pages/RequestPage";
import { ServicesDetailPage } from "./pages/ServicesDetailPage";
import { ServicesPage } from "./pages/ServicesPage";

export function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={IndexPage} />
      <Route path="/sluzby/:slug" component={ServicesDetailPage} />
      <Route path="/sluzby" component={ServicesPage} />
      <Route path="/galerie/:slug" component={GalleryPage} />
      <Route path="/galerie" component={GalleryNavigationPage} />
      <Route path="/kontakty" component={ContactPage} />
      <Route path="/poptavka" component={RequestPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}
