import styles from "./SectionHeading.module.scss";
import { PageWidth } from "components/PageWidth";

function SectionHeading(props) {
  return (
    <PageWidth>
      <h1 className={styles.sectionHeading}>{props.children}</h1>
    </PageWidth>
  );
}

export default SectionHeading;
