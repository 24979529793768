import styles from "./BackToGallery.module.scss";

function BackToGallery() {
  const redirectToGalleryPage = () => {
    window.location.href = `/galerie`;
  };

  return (
    <button class={styles.backToGallery} onClick={redirectToGalleryPage}>
      <p>Zpět ✖</p>
    </button>
  );
}

export default BackToGallery;
