import styles from "./Separator.module.scss";
import { PageWidth } from "components/PageWidth";

function Separator() {
  return (
    <PageWidth>
      <div className={styles.separator}></div>
    </PageWidth>
  );
}

export default Separator;
