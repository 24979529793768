import { Viewport } from "components/Viewport";
import ImageGallery from "react-image-gallery";
import { useParams } from "react-router-dom";
import { BackToGallery } from "components/Buttons/BackToGallery";

export function GalleryPage() {
  const { slug } = useParams();

  function importAllFromFolder(r) {
    let imageImport = {};
    r.keys().map((item, index) => {
      imageImport[item.replace("./", "")] = r(item);
    });
    return imageImport;
  }

  const importImages = () => {
    let imagesObject;

    if (slug === "vyrobky") {
      imagesObject = importAllFromFolder(
        require.context(
          `../images/gallery/vyrobky`,
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
    } else if (slug === "stroje") {
      imagesObject = importAllFromFolder(
        require.context(`../images/gallery/stroje`, false, /\.(png|jpe?g|svg)$/)
      );
    } else if (slug === "dilna") {
      imagesObject = importAllFromFolder(
        require.context(`../images/gallery/dilna`, false, /\.(png|jpe?g|svg)$/)
      );
    } else if (slug === "ostatni") {
      imagesObject = importAllFromFolder(
        require.context(
          `../images/gallery/ostatni`,
          false,
          /\.(png|jpe?g|svg)$/
        )
      );
    } else {
      return "error";
    }

    const imagesArray = Object.keys(imagesObject).map(function (key) {
      return imagesObject[key];
    });

    const imagesOutput = [];

    for (let i = 0; i < Object.keys(imagesArray).length / 2; i++) {
      imagesOutput[i] = {
        original: imagesArray[i * 2].default,
        thumbnail: imagesArray[i * 2 + 1].default,
      };
    }

    return imagesOutput;
  };

  const images = importImages();

  return (
    <Viewport>
      <BackToGallery />
      {images === "error" ? (
        <p>Oops, something went wrong</p>
      ) : (
        <ImageGallery items={images} showPlayButton={false} />
      )}
    </Viewport>
  );
}
