import styles from "./NoFormRequest.module.scss";
import { PageWidth } from "components/PageWidth";

function NoFormRequest() {
  const emailAdress = "horacekmodrany@seznam.cz";

  return (
    <PageWidth>
      <div className={styles["no-form-request"]}>
        <p>
          <span className='strong-text'>Výkresovou dokumentaci</span> a
          požadované <span className='strong-text'>množství</span> nám zašlete
          na adresu
        </p>
        <a href={`mailto:${emailAdress}`}>
          <p className={styles.email}>horacekmodrany@seznam.cz</p>
        </a>
      </div>
    </PageWidth>
  );
}

export default NoFormRequest;
