import React from "react";
import styles from "./AppHeader.module.scss";
import { PageWidth } from "components/PageWidth";
import { NavigationMenu } from "components/NavigationMenu";

function AppHeader() {
  return (
    <div className={styles.header}>
      {/* <img src='/images/logo.png' alt='Logo' className='logo' /> */}
      <PageWidth>
        <div className={styles["header-container"]}>
          <a href='/'>
            <h1 className={styles.logo}>HO-KO</h1>
          </a>

          <NavigationMenu>
            <a href='/'>Home</a>
            <a href='/poptavka'>Poptávka</a>
            <a href='/galerie'>Galerie</a>
            <a href='/kontakty'>Kontakt</a>

            {/* <a href='/sluzby'>Služby</a> */}
            
          </NavigationMenu>
        </div>
      </PageWidth>
    </div>
  );
}

export default AppHeader;
