import styles from "./ScrollDownHint.module.scss";

function ScrollDownHint() {
  return (
    <div className={styles["scroll-down-hint"]}>
      <div />
      <div />
      <div />
    </div>
  );
}

export default ScrollDownHint;
