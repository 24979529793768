import React from "react";
import { PhoneIphone, MailOutline, Room } from "@material-ui/icons/";
import styles from "./AppFooter.module.scss";
import { PageWidth } from "components/PageWidth";

function AppFooter() {
  const iconStyle = {
    color: "red",
    marginRight: "8px",
    fontSize: "35px",
  };

  const emailAdress = "horacekmodrany@seznam.cz";
  const phoneNumber = "+420-608-221-417";
  const mapsRef =
    "https://mapy.cz/zakladni?x=14.4105887&y=50.0119655&z=16&q=Herrmannova%20670%2F10%20Praha%2012%20-%20Mod%C5%99any%20143%2000&source=addr&id=8956390";

  return (
    <div className={styles.footer}>
      <PageWidth>
        <div className={styles["contacts-container"]}>
          <a href={`tel:${phoneNumber}`}>
            <div>
              <PhoneIphone style={iconStyle} />
              <p>+420 608 221 417</p>
            </div>
          </a>
          <a href={`mailto:${emailAdress}`}>
            <div>
              <MailOutline style={iconStyle} />
              <p>horacekmodrany@seznam.cz</p>
            </div>
          </a>
          <a href={mapsRef} target='_blank' rel='noopener noreferrer'>
            <div>
              <Room style={iconStyle} />
              <p>Ukázat na mapě</p>
            </div>
          </a>
        </div>
      </PageWidth>
    </div>
  );
}

export default AppFooter;
