import styles from "./BgImg.module.scss";

function BgImg(props) {
  return (
    <div className='noOverflow'>
      <div
        className={`${styles.bgImg} ${props.effect && styles[props.effect]}`}
        style={{ backgroundImage: `url("${props.bgImgUrl}")` }}
      />
    </div>
  );
}

export default BgImg;
