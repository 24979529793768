import styles from "./InteractiveMap.module.scss";
import {
  Map,
  MarkerLayer,
  Marker,
  MouseControl,
  KeyboardControl,
  ZoomControl,
} from "react-mapycz";

function InteractiveMap({ children }) {
  return (
    <div className={styles.map}>
      <Map center={{ lat: 50.0123, lng: 14.4106442 }} zoom={14}>
        <ZoomControl />
        <MouseControl zoom={true} pan={true} wheel={true} />
        <KeyboardControl />
        <MarkerLayer>
          <Marker coords={{ lat: 50.011867, lng: 14.4106442 }} />
        </MarkerLayer>
      </Map>
    </div>
  );
}

export default InteractiveMap;
