import Button from "react-bootstrap/Button";

import styles from "./ContactUs.module.scss";

function ContactUs() {
  return (
    <Button className={styles["contact-us"]} href='/poptavka'>
      Kontaktujte nás
    </Button>
  );
}

export default ContactUs;
