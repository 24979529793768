import { TextBox } from "components/TextBox";
import { Viewport } from "components/Viewport";
import { BgImg } from "components/BgImg";
import { WhiteOverlay } from "components/WhiteOverlay";
import { ContactUs } from "components/Buttons/ContactUs";
import { PageWidth } from "components/PageWidth";
import { Separator } from "components/Separator";
import { CardLayout } from "components/Card/CardLayout";
import { Card } from "components/Card/Card";
import { Thumbnail } from "components/Thumbnail";
import { SectionHeading } from "components/SectionHeading";

import WorkshopImg from "images/workshop.jpg";
import DrillImg from "images/thumbnails/drill.jpg";
import LatheImg from "images/thumbnails/lathe.jpg";
import MillingCutter from "images/thumbnails/milling-cutter.jpg";
import GrindWheel from "images/thumbnails/grind-wheel.jpg";

export function IndexPage() {
  return (
    <>
      <Viewport>
        <BgImg bgImgUrl={WorkshopImg} effect={"scaleIn"} />
        <WhiteOverlay />
        <PageWidth>
          <TextBox>
            <p>
              <span className="strong-text">Obrábění kovů od roku 1995</span>
              <br /> Malosériová výroba v oblasti soustružení,
              <br /> frézování, přesného vrtání
              <br /> a broušení naplocho
            </p>
            <ContactUs />
          </TextBox>
        </PageWidth>
      </Viewport>

      <Separator />

      <SectionHeading>Služby</SectionHeading>

      <Viewport>
        <PageWidth>
          <CardLayout>
            <Card>
              <Thumbnail image={DrillImg}>Vrtání</Thumbnail>
            </Card>
            <Card>
              <Thumbnail image={LatheImg}>Soustružení</Thumbnail>
            </Card>
            <Card>
              <Thumbnail image={MillingCutter}>Frézování</Thumbnail>
            </Card>
            <Card>
              <Thumbnail image={GrindWheel}>Broušení</Thumbnail>
            </Card>
          </CardLayout>
        </PageWidth>
      </Viewport>
    </>
  );
}
