import styles from "./PersonalInfo.module.scss";
import { PhoneIphone, MailOutline, AccountCircle } from "@material-ui/icons/";

function PersonalInfo({ children }) {
  const iconStyle = {
    color: "red",
    width: "90px",
    fontSize: "35px",
  };

  return (
    <div className={styles["personal-info"]}>
      <div>
        <PhoneIphone style={iconStyle} />
        <p>+420 608 221 417</p>
      </div>
      <div>
        <MailOutline style={iconStyle} />
        <p>horacekmodrany@seznam.cz</p>
      </div>
      <div>
        <AccountCircle style={iconStyle} />
        <p>Zdeněk Horáček</p>
      </div>
      <div>
        <div style={iconStyle} className={styles.icon}>
          IČO
        </div>
        <p>639 58 490</p>
      </div>
    </div>
  );
}

export default PersonalInfo;
