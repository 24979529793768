import styles from "./Thumbnail.module.scss";
import { BgImg } from "components/BgImg";
import { useState } from "react";

function Thumbnail({ children, image }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={styles.thumbnail}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <BgImg bgImgUrl={image} effect={isHovered && "isHovered"} />
      <div className={`${styles.overlay} ${isHovered && styles.isHovered}`} />
      <p className={isHovered ? styles.isHovered : undefined}>{children}</p>
    </div>
  );
}

export default Thumbnail;
