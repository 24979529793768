import styles from "./AreYouInterested.module.scss";
import { BgImg } from "components/BgImg";
import { WhiteOverlay } from "components/WhiteOverlay";
import HouseImg from "images/house.jpg";
import { TextBox } from "components/TextBox";
import { ContactUs } from "components/Buttons/ContactUs";
import { PageWidth } from "components/PageWidth";

function AreYouInterested() {
  return (
    <div className={styles["are-you-interested"]}>
      <BgImg bgImgUrl={HouseImg} effect={"scaleIn"} />
      <WhiteOverlay />
      <PageWidth>
        <TextBox>
          <p>Máte zájem o naše služby?</p>
          <ContactUs />
        </TextBox>
      </PageWidth>
    </div>
  );
}

export default AreYouInterested;
