import { Viewport } from "components/Viewport";
import { PageWidth } from "components/PageWidth";
import { ContactUsLayout } from "components/ContactUs/ContactUsLayout";
import { PersonalInfo } from "components/ContactUs/PersonalInfo";
import { Address } from "components/ContactUs/Address";
import { AreYouInterested } from "components/AreYouInterested";

export function ContactPage() {
  return (
    <>
      <Viewport>
        <AreYouInterested />
        <PageWidth>
          <ContactUsLayout>
            <PersonalInfo />
            <Address />
          </ContactUsLayout>
        </PageWidth>
      </Viewport>
    </>
  );
}
