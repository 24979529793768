import styles from "./RequestInstructions.module.scss";

function RequestInstructions({ children, image, n }) {
  return (
    <div className={styles["request-instructions"]}>
      <div className={styles.numbering}>{n}</div>
      <img src={image} alt='requestImage' />
      <p>{children}</p>
    </div>
  );
}

export default RequestInstructions;
