import { Viewport } from "components/Viewport";
import { PageWidth } from "components/PageWidth";
import { CardLayout } from "components/Card/CardLayout";
import { Card } from "components/Card/Card";
import { Thumbnail } from "components/Thumbnail";

import ProductImage from "images/thumbnails/product.jpg";
import LatheImage from "images/thumbnails/lathe.jpg";
import WorkshopImage from "images/thumbnails/workshop.jpg";
import CabinetImage from "images/thumbnails/cabinet.jpg";

export function GalleryNavigationPage() {
  return (
    <>
      <Viewport>
        <PageWidth>
          <CardLayout>
            <Card link={"/galerie/vyrobky"}>
              <Thumbnail image={ProductImage}>Výrobky</Thumbnail>
            </Card>
            <Card link={"/galerie/stroje"}>
              <Thumbnail image={LatheImage}>Stroje</Thumbnail>
            </Card>
            <Card link={"/galerie/dilna"}>
              <Thumbnail image={WorkshopImage}>Dílna</Thumbnail>
            </Card>
            <Card link={"/galerie/ostatni"}>
              <Thumbnail image={CabinetImage}>Ostatní</Thumbnail>
            </Card>
          </CardLayout>
        </PageWidth>
      </Viewport>
    </>
  );
}
