import styles from "./Address.module.scss";
import { InteractiveMap } from "components/ContactUs/InteractiveMap";

function Address({ children }) {
  return (
    <div className={styles.address}>
      <InteractiveMap />
      <p>
        Herrmannova 670/10
        <br />
        Praha 12 - Modřany
        <br />
        143 00
      </p>
    </div>
  );
}

export default Address;
